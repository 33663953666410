import React from "react"
import Button from "components/_ui/Button"
import styled from "@emotion/styled"
import dimensions from "styles/dimensions"
import PropTypes from "prop-types"
import colors from "styles/colors"

const ConsumingContainer = styled("div")`
  padding-top: 1em;
  display: grid;
  grid-template-columns: 1fr 8em;
  grid-gap: 3em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 3em 1fr;
    grid-gap: 2em;
  }
`

const ConsumingLinkContainer = styled("div")`
  padding-top: 1em;
  padding-bottom: 3em;
  display: flex;
  flex-direction: column;

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-row: 2;
  }
`

const ConsumingLink = styled("a")`
  margin-bottom: 1.5em;
  font-weight: 600;
  line-height: 1.9;
  text-decoration: none;
  color: currentColor;

  span {
    margin-left: 1em;
    transform: translateX(-8px);
    display: inline-block;
    opacity: 0;
    transition: all 400ms ease-in-out;
  }

  &:nth-of-type(1) {
    color: ${colors.blue500};
  }
  &:nth-of-type(2) {
    color: ${colors.orange500};
  }
  &:nth-of-type(3) {
    color: ${colors.purple500};
  }
  &:nth-of-type(4) {
    color: ${colors.green500};
  }
  &:nth-of-type(5) {
    color: ${colors.teal500};
  }

  &:hover {
    cursor: pointer;
    transition: all 100ms ease-in-out;

    &:nth-of-type(1) {
      color: ${colors.blue600};
      background-color: ${colors.blue200};
    }
    &:nth-of-type(2) {
      color: ${colors.orange600};
      background-color: ${colors.orange200};
    }
    &:nth-of-type(3) {
      color: ${colors.purple600};
      background-color: ${colors.purple200};
    }
    &:nth-of-type(4) {
      color: ${colors.green600};
      background-color: ${colors.green200};
    }
    &:nth-of-type(5) {
      color: ${colors.teal600};
      background-color: ${colors.teal200};
    }
  }
`

// const AboutBio = styled("div")`
//   padding-bottom: 3em;
//   max-width: 480px;

//   @media (max-width: ${dimensions.maxwidthMobile}px) {
//     grid-row: 2;
//   }
// `

const ConsumingActions = styled("div")`
  padding-top: 1em;
  padding-bottom: 3em;

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    padding: 0;
    grid-column: 1 / -1;
    grid-row: 1;
  }
`

const Consuming = ({ consumingLinks }) => (
  <ConsumingContainer>
    <ConsumingLinkContainer>
      {consumingLinks.map((content, i) => (
        <ConsumingLink
          key={i}
          href={content.content_type[0].spans[0].data.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content.content_type[0].text}
          <span>&#8594;</span>
        </ConsumingLink>
      ))}
    </ConsumingLinkContainer>
    <ConsumingActions>
      <a href="/bookshelf" target="_blank" rel="noopener noreferrer">
        <Button className="Button--secondary">See More</Button>
      </a>
    </ConsumingActions>
  </ConsumingContainer>
)

export default Consuming

Consuming.propTypes = {
  consumingLinks: PropTypes.array.isRequired,
}
