import React from "react"
import "react-image-lightbox/style.css"
import "./gallery.css"
import { useStaticQuery, graphql } from "gatsby"
import Lightbox from "react-image-lightbox"

const ImageGallery = ({ photos }) => {
  const [photoIndex, setPhotoIndex] = React.useState(-1)
  const [isOpen, setIsOpen] = React.useState(false)

  const data = useStaticQuery(graphql`
    query CloudinaryImage {
      allCloudinaryMedia {
        edges {
          node {
            secure_url
            tags
          }
        }
      }
    }
  `)

  const photoImgs = data.allCloudinaryMedia.edges.filter(photo =>
    photo.node.tags.includes("photo")
  )
  const artworkImgs = data.allCloudinaryMedia.edges.filter(photo =>
    photo.node.tags.includes("artwork")
  )

  const artworkImages = [...new Set(artworkImgs)]
  const photoImages = [...new Set(photoImgs)]

  const clImages = photos ? photoImages : artworkImages

  const onClose = () => setIsOpen(false)

  const onPrev = () =>
    setPhotoIndex((photoIndex + clImages.length - 1) % clImages.length)

  const onNext = () =>
    setPhotoIndex((photoIndex + clImages.length + 1) % clImages.length)

  const onClickImage = index => {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={clImages[photoIndex].node.secure_url}
          nextSrc={clImages[(photoIndex + 1) % clImages.length].node.secure_url}
          prevSrc={
            clImages[(photoIndex + clImages.length - 1) % clImages.length].node
              .secure_url
          }
          onCloseRequest={onClose}
          onMovePrevRequest={onPrev}
          onMoveNextRequest={onNext}
        />
      )}
      <div className="image-grid">
        {clImages.map((image, index) => (
          <div className="image-item" key={`${index}-cl`}>
            <img
              className="gallery-img"
              onClick={() => onClickImage(index)}
              src={image.node.secure_url}
              alt={""}
            />
          </div>
        ))}
      </div>
    </>
  )
}
export default ImageGallery
