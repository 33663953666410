import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import dimensions from "styles/dimensions"
import Layout from "components/Layout"
import { Section as Sec } from "."
import colors from "styles/colors"
import { RichText } from "prismic-reactjs"
import Button from "components/_ui/Button"
import { Transition } from "react-transition-group"
import ImageGallery from "components/ImageGallery"

const photos_title = "📷 Photos"
const art_title = "🖼 Art"

const PageTitle = styled("h1")`
  margin-bottom: 1em;
`

const ArtTitle = styled("h2")`
  margin: 0.2em 0em;
  color: ${colors.orange500};
`

const Section = styled(Sec)`
  margin-bottom: 2em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    margin-bottom: 0em;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    margin-bottom: 0em;
  }
`

const GalleryExtra = styled("div")`
  transition: all 300ms ease-in-out;
  opacity: ${({ state }) =>
    state === "entering" || state === "exiting" ? 0 : 1};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`

const ArtDescription = styled("div")`
  margin: 0 auto;

  .block-img {
    margin-top: 3.5em;
    margin-bottom: 0.5em;

    img {
      width: 100%;
    }
  }

  a {
    /* text-decoration: none; */
    transition: all 100ms ease-in-out;

    &:nth-of-type(1) {
      color: ${colors.orange500};
    }

    &:hover {
      cursor: pointer;
      transition: all 100ms ease-in-out;

      &:nth-of-type(1) {
        color: ${colors.orange500};
        background-color: ${colors.orange200};
      }
    }
  }

  .embed {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 0 2em 0;
  }
`

const ArtSubtitle = styled("h3")`
  margin: 0.5em 0em;
  margin-bottom: 1em;
  font-weight: 300;
  font-family: sans-serif;
  color: ${colors.grey500};
`

const ButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
`

const Header = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Art = ({ art, meta, images }) => {
  const [pageToShow, setPageToShow] = React.useState(0)

  const togglePage = () => setPageToShow(!pageToShow)

  return (
    <>
      <Helmet
        title={`🖼 art | dhruv c`}
        titleTemplate={`%s | art | dhruv c`}
        meta={[
          {
            name: `description`,
            content: meta.description,
          },
          {
            property: `og:title`,
            content: `art | dhruv c`,
          },
          {
            property: `og:description`,
            content: meta.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:title`,
            content: meta.title,
          },
          {
            name: `twitter:description`,
            content: meta.description,
          },
        ].concat(meta)}
      />
      <Layout>
        <Header>
          <PageTitle>Art</PageTitle>
          <ButtonContainer>
            <Button className="Button--primary" onClick={togglePage}>
              {pageToShow ? photos_title : art_title}
            </Button>
          </ButtonContainer>
        </Header>
        <Section>
          {art.art_title[0].text && art.art_subtitle[0].text && (
            <Section>
              {art.art_title[0].text && (
                <ArtTitle>{art.art_title[0].text}</ArtTitle>
              )}
              {art.art_subtitle[0].text && (
                <ArtSubtitle>{art.art_subtitle[0].text}</ArtSubtitle>
              )}
            </Section>
          )}
          {art.art_description[0].text && (
            <Section>
              <ArtDescription>
                {RichText.render(art.art_description)}
              </ArtDescription>
            </Section>
          )}
          <Transition in={!pageToShow} timeout={10}>
            {state => (
              // state change: exited -> entering -> entered -> exiting -> exited
              <GalleryExtra state={state}>
                <Section>
                  <ArtTitle> Photos</ArtTitle>
                  <ArtDescription>
                    {RichText.render(art.art_primary_description)}
                  </ArtDescription>
                </Section>

                <Section>
                  <ImageGallery photos={true} />
                </Section>
              </GalleryExtra>
            )}
          </Transition>
          <Transition in={pageToShow} timeout={10}>
            {state => (
              // state change: exited -> entering -> entered -> exiting -> exited
              <GalleryExtra state={state}>
                <Section>
                  <ArtTitle> Drawing / Illustrations </ArtTitle>
                  <ArtDescription>
                    {RichText.render(art.art_secondary_description)}
                  </ArtDescription>
                </Section>

                <Section>
                  <ImageGallery photos={false} />
                </Section>
              </GalleryExtra>
            )}
          </Transition>
        </Section>
        <ImageGallery />
      </Layout>
    </>
  )
}

// const OldGallery = (art, pageToShow) => {
//   return (
//     <>
//       {art.art_title[0].text && art.art_subtitle[0].text && (
//         <Section>
//           {art.art_title[0].text && (
//             <ArtTitle>{art.art_title[0].text}</ArtTitle>
//           )}
//           {art.art_subtitle[0].text && (
//             <ArtSubtitle>{art.art_subtitle[0].text}</ArtSubtitle>
//           )}
//         </Section>
//       )}
//       {art.art_description[0].text && (
//         <Section>
//           <ArtDescription>
//             {RichText.render(art.art_description)}
//           </ArtDescription>
//         </Section>
//       )}
//       <Transition in={!pageToShow} timeout={10}>
//         {state => (
//           // state change: exited -> entering -> entered -> exiting -> exited
//           <GalleryExtra state={state}>
//             <Section>
//               <ArtTitle> Photos</ArtTitle>
//               <ArtDescription>
//                 {RichText.render(art.art_primary_description)}
//               </ArtDescription>
//             </Section>

//             <Section>{/* <Gallery /> */}</Section>
//           </GalleryExtra>
//         )}
//       </Transition>
//       <Transition in={pageToShow} timeout={10}>
//         {state => (
//           // state change: exited -> entering -> entered -> exiting -> exited
//           <GalleryExtra state={state}>
//             <Section>
//               <ArtTitle> Drawing / Illustrations </ArtTitle>
//               <ArtDescription>
//                 {RichText.render(art.art_secondary_description)}
//               </ArtDescription>
//             </Section>

//             <Section>{/* <Gallery /> */}</Section>
//           </GalleryExtra>
//         )}
//       </Transition>
//     </>
//   )
// }

export default ({ data }) => {
  const art = data.prismic.allArts.edges.slice(0, 1).pop()
  const images = data.allCloudinaryMedia.edges
  const meta = data.site.siteMetadata
  if (!art || !images) return null

  return <Art art={art.node} images={images} meta={meta} />
}

Art.propTypes = {
  art: PropTypes.array.isRequired,
  images: PropTypes.array,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    allCloudinaryMedia {
      edges {
        node {
          secure_url
        }
      }
    }
    prismic {
      allArts {
        edges {
          node {
            art_title
            art_subtitle
            art_description
            art_primary_description
            art_secondary_description
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
